<template>
    <div class="Profile-rating">
<!--        <ul class="nav nav-tabs rating-tabs">-->
<!--            <li class="nav-item">-->
<!--                <a class="nav-link rating-tabs__title" -->
<!--                    @click.prevent="setActive('my-rating')" -->
<!--                    :class="{ active: isActive('my-rating') }" href="#my-rating">-->
<!--                    {{$t("profile.rating.personal-rating")}}-->
<!--                </a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--                <a class="nav-link rating-tabs__title" -->
<!--                    @click.prevent="setActive('class-rating')" -->
<!--                    :class="{ active: isActive('class-rating') }" href="#class-rating">-->
<!--                    {{$t("profile.rating.class-rating")}}-->
<!--                </a>-->
<!--            </li>-->
<!--        </ul>-->
        <div class="tab-content rating-tab-content">
            <div class="tab-pane fade" :class="{ 'active show': isActive('my-rating') }" id="my-rating">
                <div class="my-rating-tab">
                    <div class="rating-block">
                        <div class="my-rating">
                            <div class="my-rating__title">
                                {{$t("profile.rating.my-rating")}}
                            </div>
                            <div class="my-rating__desc-block">
                                <div class="my-rating__place">
                                    <div class="my-rating__place-num">{{ data.classPlace }}</div>
                                    <div class="my-rating__place-desc">
                                        {{$t("profile.rating.place-in-class")}}
                                    </div>
                                </div>
                                <div class="my-rating__place">
                                    <div class="my-rating__place-num">{{ data.streamPlace }}</div>
                                    <div class="my-rating__place-desc">
                                        {{$t("profile.rating.place-on-flow")}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="leaders">
                            <div class="leaders__header">
                                <div class="leaders__title">
                                    {{$t("profile.rating.list-of-leaders")}}
                                </div>
                                <div>
                                    <ul class="nav nav-tabs leaders-tabs">
                                        <li class="nav-item">
                                            <a class="nav-link leaders-tabs__title" 
                                                @click.prevent="setActiveLeaders('leaders-class')" 
                                                :class="{ active: isActiveLeaders('leaders-class') }" href="#leaders-class">
                                                {{$t("profile.rating.class")}}
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link leaders-tabs__title" 
                                                @click.prevent="setActiveLeaders('leaders-flow')" 
                                                :class="{ active: isActiveLeaders('leaders-flow') }" href="#leaders-flow">
                                                {{$t("profile.rating.flow")}}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade" :class="{ 'active show': isActiveLeaders('leaders-class') }" id="leaders-class">
                                    <ol class="leaders-list">
                                        <li class="leaders-list__item" :class="{'leaders-list__item--first': leader.is_you}" v-for="(leader, key) in data.classLeaders" v-if="key < 5">
                                            {{ leader.name }}
                                        </li>
                                    </ol>
                                </div>
                                <div class="tab-pane fade" :class="{ 'active show': isActiveLeaders('leaders-flow') }" id="leaders-flow">
                                    <ol class="leaders-list">
                                        <li class="leaders-list__item" :class="{'leaders-list__item--first': leader.is_you}" v-for="(leader, key) in data.streamLeaders" v-if="key < 5">
                                            {{ leader.name }}
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="performance">
                        <div class="performance__header">
                            <div class="performance__title">
                                {{$t("profile.rating.academic-performance")}}
                            </div>
                            <el-button @click="$router.push({ name: 'performance' })"
                                       type="text performance__more">{{ $t("profile.rating.more") }}
                                <i class="el-icon-arrow-right"></i>
                            </el-button>
                        </div>
                        <div class="performance__score-block">
                            <div class="performance__score">
                                {{ data.gradesAvg }}
                            </div>
                            <div class="performance__score-desc">
                                {{$t("profile.rating.average-score")}}
                            </div>
                        </div>
                        <vue-apex-charts type="line" height="250" :options="chartOptions" :series="series"></vue-apex-charts>
                    </div>
                </div>
            </div>
<!--            <div class="tab-pane fade" :class="{ 'active show': isActive('class-rating') }" id="class-rating">-->
<!--                <div class="class-rating-tab">-->
<!--                    <div class="rating-block">-->
<!--                        <div class="my-rating">-->
<!--                            <div class="my-rating__title">-->
<!--                                {{$t("profile.rating.my-rating")}}-->
<!--                            </div>-->
<!--                            <div class="my-rating__desc-block">-->
<!--                                <div class="my-rating__place">-->
<!--                                    <div class="my-rating__place-num">{{ data.schoolPlace }}</div>-->
<!--                                    <div class="my-rating__place-desc">-->
<!--                                        {{$t("profile.rating.place-in-school")}}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="my-rating__place">-->
<!--                                    <div class="my-rating__place-num">{{ data.streamPlace }}</div>-->
<!--                                    <div class="my-rating__place-desc">-->
<!--                                        {{$t("profile.rating.place-on-flow")}}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="leaders">-->
<!--                            <div class="leaders__header">-->
<!--                                <div class="leaders__title">-->
<!--                                    {{$t("profile.rating.list-of-leaders")}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <ul class="nav nav-tabs leaders-tabs">-->
<!--                                        <li class="nav-item">-->
<!--                                            <a class="nav-link leaders-tabs__title" -->
<!--                                                @click.prevent="setActiveLeadersClasses('leaders-school')" -->
<!--                                                :class="{ active: isActiveLeadersClasses('leaders-school') }" href="#leaders-school">-->
<!--                                                {{$t("profile.rating.school")}}-->
<!--                                            </a>-->
<!--                                        </li>-->
<!--                                        <li class="nav-item">-->
<!--                                            <a class="nav-link leaders-tabs__title" -->
<!--                                                @click.prevent="setActiveLeadersClasses('leaders-flow-2')" -->
<!--                                                :class="{ active: isActiveLeadersClasses('leaders-flow-2') }" href="#leaders-flow-2">-->
<!--                                                {{$t("profile.rating.flow")}}-->
<!--                                            </a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="tab-content">-->
<!--                                <div class="tab-pane fade" :class="{ 'active show': isActiveLeadersClasses('leaders-school') }" id="leaders-school">-->
<!--                                    <ol class="leaders-list">-->
<!--                                        <li class="leaders-list__item" :class="{'leaders-list__item&#45;&#45;first': leader.is_you}" v-for="(leader, key) in data.schoolLeaders" v-if="key < 5">-->
<!--                                            {{ leader.name }}-->
<!--                                        </li>-->
<!--                                    </ol>-->
<!--                                </div>-->
<!--                                <div class="tab-pane fade" :class="{ 'active show': isActiveLeadersClasses('leaders-flow-2') }" id="leaders-flow-2">-->
<!--                                    <ol class="leaders-list">-->
<!--                                        <li class="leaders-list__item" :class="{'leaders-list__item&#45;&#45;first': leader.is_you}" v-for="(leader, key) in data.streamLeaders" v-if="key < 5">-->
<!--                                            {{ leader.name }}-->
<!--                                        </li>-->
<!--                                    </ol>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--             </div>-->
        </div>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts
    },
    data() {
        return {
            data: [],
            leaders: 'class',
            activeItem: 'my-rating',
            activeItemLeaders: 'leaders-class',
            activeItemLeadersClass: 'leaders-school',
            series: [],
            chartOptions: {
                chart: {
                    height: 300,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                },
                colors: ['#473F95'],
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    hover: {
                        size: 8
                    }
                },
                stroke: {
                    width: 2,
                    curve: 'smooth'
                },
                tooltip: {
                    marker: {
                        tooltip: {
                            shared: false,
                            intersect: true
                        },
                        show: false,
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: () => '',
                        },
                    },
                }
            },
        }
    },
    mounted() {
        this.$http.get(`${window.API_ROOT}/api/diary/rating`)
            .then((res) => {
                this.data = res.body
                this.chartRender()
            }).catch(() => {
            })
    },
    methods: {
        chartRender() {
            let labels = [];
            let classData = [];
            this.data.grades.forEach(item => {
                classData.push(item.control)
                labels.push(item.label)
            })
            this.series = []
            this.series.push({
                name: '',
                data: classData
            })
            this.chartOptions = {...this.chartOptions, ...{
                    xaxis: {
                        tickPlacement: 'between',
                        categories: labels,
                        tooltip: {
                            enabled: false
                        }
                    },
                    yaxis: {
                        tickPlacement: 'between',
                        min: 0,
                        max: 100,
                    }
                }
            }
        },
        isActive (menuItem) {
            return this.activeItem === menuItem
        },
        setActive (menuItem) {
            this.activeItem = menuItem
        },
        isActiveLeaders (menuItem) {
            return this.activeItemLeaders === menuItem
        },
        setActiveLeaders (menuItem) {
            this.activeItemLeaders = menuItem
        },
        isActiveLeadersClasses (menuItem) {
            return this.activeItemLeadersClass === menuItem
        },
        setActiveLeadersClasses (menuItem) {
            this.activeItemLeadersClass = menuItem
        },
    },
}
</script>
<style lang="less" scoped>
.rating-tabs {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 54px;
    max-width: 100%;
}
.nav-tabs.rating-tabs {
    border-bottom: none;
}
.rating-tabs__title {
    width: 218px;
    text-align: center;
    border: 1px solid #473F95;
    box-sizing: border-box;
    padding: 8px 24px;
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
.rating-tabs__title.active {
    background: #473F95;
    border: 1px solid #473F95;
    color: #FFFFFF;
}
.rating-tabs__title:hover {
    border: 1px solid #473F95;
}
.rating-tabs .nav-item:first-child .rating-tabs__title {
    border-radius: 5px 0 0 5px;
} 
.rating-tabs .nav-item:last-child .rating-tabs__title {
    border-radius: 0 5px 5px 0;
} 
.my-rating {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 12px;
    width: 390px;
    min-height: 160px;
    background: #FFFFFF url("/images/profile/rating-bg.svg") no-repeat bottom;
    background-size: contain;
    margin-bottom: 20px;
}
.my-rating__title {
    color: #473F95;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 10px;
}
.my-rating__desc-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.my-rating__desc-block  .my-rating__place:first-child {
    margin-right: 20px;
}
.my-rating__place {
    background-color: #fff;
    border-radius: 50%;
    width: 89px;
    height: 89px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.my-rating__place-num {
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #473F95;
}
.my-rating__place-desc {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #473F95;
    max-width: 60px;
}
.leaders {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 12px;
    width: 390px;
}
.leaders__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid #EEEAFB;
    width: 100%;
}
.leaders-tabs.nav-tabs {
    border-bottom: none;
}
.leaders-tabs.nav-tabs .nav-link, 
.leaders-tabs.nav-tabs .nav-item .nav-link {
    color: #473F95;
    font-size: 12px;
    line-height: 14px;
    border: none;
    background-color: transparent;
    padding: 0 0 4px 0;
}
.leaders-tabs.nav-tabs .nav-link.active, 
.leaders-tabs.nav-tabs .nav-item.show .nav-link {
    color: #473F95;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #473F95;
}
.leaders-tabs.nav-tabs .nav-link, 
.leaders-tabs.nav-tabs .nav-item .nav-link:first-child {
    margin-right: 12px;
}
.leaders-list {
    color: #303030;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    padding-left: 12px;
}
.leaders-list .leaders-list__item--first {
    color: #D23168;
    font-weight: bold;
}

.leaders-list__item {
    padding-top: 12px;
}
.leaders-list__item--you {
    color: #D23168;
    font-weight: bold;
}
.performance {
    margin-left: 20px;
    width: 740px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 12px;
}
.performance__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid #EEEAFB;
    width: 100%;
}
.performance__more {
    font-size: 14px;
    line-height: 16px;
    color: rgba(71, 63, 149, 0.68);
    background-color: transparent;
    border: none;
    padding: 6px 0;
}
.performance__more img {
    margin-left: 12px;
}
.my-rating-tab {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.performance__score-block {
    padding-left: 16px;
    padding-top: 12px;
}
.performance__score {
    color: #D23168;
    font-weight: bold;
    font-size: 31px;
    line-height: 36px;
    padding-bottom: 4px;
}
.performance__score-desc {
    color: #303030;
    font-size: 14px;
    line-height: 16px;
}
.class-rating-tab .rating-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.class-rating-tab .rating-block .my-rating {
    margin-bottom: 0;
    margin-right: 20px;
    min-height: 195px;
}
.performance__title,
.leaders__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #473F95;
}
@media (max-width: 991px) {
    .rating-tabs__title {
       width: auto;
       padding: 14px;
   }
   .rating-tabs {
       margin-bottom: 30px;
   }
   .rating-tabs .nav-item:first-child .rating-tabs__title {
        border-radius: 12px;    
        margin-right: 12px;
        padding: 14px;
   }
   .rating-tabs .nav-item:last-child .rating-tabs__title {
        border-radius: 12px;    
        padding: 14px;
   }
   .my-rating-tab,
   .class-rating-tab .rating-block {
        flex-direction: column;
   }
   .my-rating,
   .leaders {
       width: 100%;
       margin-bottom: 16px;
   }
    .performance {
       width: 100%;
       margin-left: 0;
   }
   .class-rating-tab .rating-block .my-rating {
       margin-right: 0;
       margin-bottom: 16px;
        min-height: 160px;
   }
   .performance__score {
       font-weight: bold;
        font-size: 25px;
        line-height: 29px;
   }

}
</style>